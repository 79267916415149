import { Button, Grid, View } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Search from "./Search";

const Dashboard = () => {
  const shadowProp = {
    boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)",
  };
  const [user, setUser] = useState();
  const navigate = useNavigate();

  async function getCurrentUser() {
    try {
      const authUser = await Auth.currentAuthenticatedUser();
      //  console.log("UUU",JSON.stringify(authUser));
      setUser(authUser);
    } catch (e) {
      navigate("/");
    }
  }

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <div>
      <View backgroundColor={"#eff0f0"} minHeight={"100vh"}>
        <View>
          <Header></Header>
        </View>

        <View>
          <Search></Search>
        </View>
      </View>
    </div>
  );
};

export default Dashboard;

import {
  View,
  Flex,
  Alert ,
  TextField,
  Badge,
  ThemeProvider,
  Theme,
  Text,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@aws-amplify/ui-react";
import "../App.css";
import { useEffect } from "react";

const theme: Theme = {
  name: "badge-theme",
  tokens: {
    components: {
      badge: {
        // // Default styles
        color: { value: "{colors.white}" },
        fontWeight: { value: "{fontWeights.bold}" },
        fontSize: { value: "{fontSizes.5px}" },
        paddingVertical: { value: "{space.small}" },
        paddingHorizontal: { value: "{space.medium}" },
        borderRadius: { value: "{radii.small}" },
      },
    },
  },
};

export const ViewResult = ({ data }) => {
  var customApprovedSenetence = process.env.REACT_APP_APPROVED_SENETENCE
  useEffect(() => {
    console.log(
      "company : ",
      data.comp_name + "\n",
      "Creditscore : ",
      data.credit_score + "\n",
      "Providers : ",
      data.providers
    );
  }, []);

  return (
    <div>
      <ThemeProvider theme={theme} colorMode="light">
        <Flex
          justifyContent="center"
          marginTop="20px"
          className="grade-flex"
          wrap="wrap"
        >
          <Badge className="badge GradeA">
            <span className="badge-head">GRADE A</span>
            <span className="badge-para">Lowest Risk</span>
          </Badge>
          <Badge className="badge GradeB">
            <span className="badge-head">GRADE B</span>
            <span className="badge-para">Moderate Risk</span>
          </Badge>
          <Badge className="badge GradeC">
            <span className="badge-head">GRADE C</span>
            <span className="badge-para">High Risk</span>
          </Badge>
          <Badge className="badge GradeD">
            <span className="badge-head">GRADE D</span>
            <span className="badge-para">Extreme Risk</span>
          </Badge>
          <Badge className="badge GradeE">
            <span className="badge-head">GRADE E</span>
            <span className="badge-para">Too New to rate</span>
          </Badge>
          <Badge className="badge GradeF">
            <span className="badge-head">GRADE F</span>
            <span className="badge-para">Scores Not Available</span>
          </Badge>
        </Flex>
      </ThemeProvider>
      <Flex
        className="grade-table-flex"
        justifyContent={"center"}
        paddingTop={"35px"}
        paddingBottom={"50px"}
        gap={"5rem"}
        boxShadow={"rgba(13, 26, 38, 0.25) 0px 4px 12px"}
        marginTop={"35px"}
      >
         <View style={{ width: "50%" }}>
          <Table className="grade-table">
            <TableBody>
              <TableRow>
                <TableCell as="th">Company Name</TableCell>
                <TableCell>{data.comp_name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell as="th">MC_FF</TableCell>
                <TableCell>
                  {data.MC == null || data.MC == "" ? "-" : data.MC}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell as="th">City</TableCell>
                <TableCell>{data.city}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell as="th">Address</TableCell>
                <TableCell>{data.address}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell as="th">Phone No</TableCell>
                <TableCell>{data.phone}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell as="th">State</TableCell>
                <TableCell>{data.state}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell as="th">ZipCode</TableCell>
                <TableCell>{data.zipcode}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </View>
        <View style={{ width: "50%" }}>
        
          <Grid gap="1rem">
            <Flex className="credit-flex">
              {/* Credit Score */}
              {data.credit_score >= 90 ? (
                <View
                  className="credit-days-box"
                  backgroundImage={
                    "linear-gradient(rgb(139 218 120), rgb(31 163 31))"
                  }
                >
                  <Text className="box-head" variation="primary">
                    {data.credit_score}
                  </Text>
                  <Text variation="primary" className="box-para">
                    Credit Score
                  </Text>
                </View>
              ) : data.credit_score < 90 && data.credit_score >= 76 ? (
                data.providers >= 15 ? (
                  <View
                    className="credit-days-box"
                    backgroundImage={
                      "linear-gradient(rgb(22, 140, 219),rgb(71, 167, 247))"
                    }
                  >
                    <Text className="box-head" variation="primary">
                      {data.credit_score}
                    </Text>
                    <Text variation="primary" className="box-para">
                      Credit Score
                    </Text>
                  </View>
                ) : data.providers >= 8 ? (
                  <View
                    className="credit-days-box"
                    backgroundImage={
                      "linear-gradient(rgb(231 114 92), rgb(218 47 47))"
                    }
                  >
                    <Text className="box-head" variation="primary">
                      {data.credit_score}
                    </Text>
                    <Text className="box-para" variation="primary">
                      Credit Score
                    </Text>
                  </View>
                ) : (
                  <View
                    className="credit-days-box"
                    backgroundImage={
                      "linear-gradient(rgb(119, 115, 115), rgb(170, 161, 161))"
                    }
                  >
                    <Text className="box-head" variation="primary">
                      {data.credit_score}
                    </Text>
                    <Text className="box-para" variation="primary">
                      Credit Score
                    </Text>
                  </View>
                )
              ) : data.credit_score < 76 && data.credit_score >= 60 ? (
                <View
                  className="credit-days-box"
                  backgroundImage={
                    "linear-gradient(rgb(228 182 106),rgb(240 125 18))"
                  }
                >
                  <Text className="box-head" variation="primary">
                    {data.credit_score}
                  </Text>
                  <Text className="box-para" variation="primary">
                    Credit Score
                  </Text>
                </View>
              ) : data.credit_score < 60 && data.credit_score >= 1 ? (
                data.providers >= 8 ? (
                  <View
                    className="credit-days-box"
                    backgroundImage={
                      "linear-gradient(rgb(231 114 92), rgb(218 47 47))"
                    }
                  >
                    <Text className="box-head" variation="primary">
                      {data.credit_score}
                    </Text>
                    <Text className="box-para" variation="primary">
                      Credit Score
                    </Text>
                  </View>
                ) : (
                  <View
                    className="credit-days-box"
                    backgroundImage={
                      "linear-gradient(rgb(119, 115, 115), rgb(170, 161, 161))"
                    }
                  >
                    <Text className="box-head" variation="primary">
                      {data.credit_score}
                    </Text>
                    <Text className="box-para" variation="primary">
                      Credit Score
                    </Text>
                  </View>
                )
              ) : (
                <View
                  className="credit-days-box"
                  backgroundImage={
                    "linear-gradient(rgb(245, 229, 7), rgb(195, 218, 47))"
                  }
                >
                  <Text className="box-head" variation="primary">
                    {data.credit_score}
                  </Text>
                  <Text className="box-para" variation="primary">
                    Credit Score
                  </Text>
                </View>
              )}

              {/* days to pay */}
              {data.credit_score >= 90 ? (
                <View
                  className="credit-days-box"
                  backgroundImage={
                    "linear-gradient(rgb(139 218 120), rgb(31 163 31))"
                  }
                >
                  <Text className="box-head" variation="primary">
                    {data.Day_to_day}
                  </Text>
                  <Text variation="primary" className="box-para">
                    Days To Pay
                  </Text>
                </View>
              ) : data.credit_score < 90 && data.credit_score >= 76 ? (
                data.providers >= 15 ? (
                  <View
                    className="credit-days-box"
                    backgroundImage={
                      "linear-gradient(rgb(22, 140, 219),rgb(71, 167, 247))" // Grade-B
                    }
                  >
                    <Text className="box-head" variation="primary">
                      {data.Day_to_day}
                    </Text>
                    <Text variation="primary" className="box-para">
                      Days To Pay
                    </Text>
                  </View>
                ) : data.providers >= 8 ? (
                  <View
                    className="credit-days-box"
                    backgroundImage={
                      "linear-gradient(rgb(231 114 92), rgb(218 47 47))"
                    }
                  >
                    <Text className="box-head" variation="primary">
                      {data.Day_to_day}
                    </Text>
                    <Text variation="primary" className="box-para">
                      Days To Pay
                    </Text>
                  </View>
                ) : (
                  <View
                    className="credit-days-box"
                    backgroundImage={
                      "linear-gradient(rgb(119, 115, 115), rgb(170, 161, 161))"
                    }
                  >
                    <Text className="box-head" variation="primary">
                      {data.Day_to_day}
                    </Text>
                    <Text variation="primary" className="box-para">
                      Days To Pay
                    </Text>
                  </View>
                )
              ) : data.credit_score < 76 && data.credit_score >= 60 ? (
                <View
                  className="credit-days-box"
                  backgroundImage={
                    "linear-gradient(rgb(228 182 106),rgb(240 125 18))"
                  }
                >
                  <Text className="box-head" variation="primary">
                    {data.Day_to_day}
                  </Text>
                  <Text variation="primary" className="box-para">
                    Days To Pay
                  </Text>
                </View>
              ) : data.credit_score < 60 && data.credit_score >= 1 ? (
                data.providers >= 8 ? (
                  <View
                    className="credit-days-box"
                    backgroundImage={
                      "linear-gradient(rgb(231 114 92), rgb(218 47 47))"
                    }
                  >
                    <Text className="box-head" variation="primary">
                      {data.Day_to_day}
                    </Text>
                    <Text variation="primary" className="box-para">
                      Days To Pay
                    </Text>
                  </View>
                ) : (
                  <View
                    className="credit-days-box"
                    backgroundImage={
                      "linear-gradient(rgb(119, 115, 115), rgb(170, 161, 161))"
                    }
                  >
                    <Text className="box-head" variation="primary">
                      {data.Day_to_day}
                    </Text>
                    <Text variation="primary" className="box-para">
                      Days To Pay
                    </Text>
                  </View>
                )
              ) : (
                <View
                  className="credit-days-box"
                  backgroundImage={
                    "linear-gradient(rgb(245, 229, 7), rgb(195, 218, 47))"
                  }
                >
                  <Text className="box-head" variation="primary">
                    {data.Day_to_day}
                  </Text>
                  <Text variation="primary" className="box-para">
                    Days To Pay
                  </Text>
                </View>
              )}
            </Flex>
            <View>
              {data.credit_score >= 90 ? (
                <Badge className="Gradebox tempGradeA">
                  A - APPROVED, it is lowest risk.
                </Badge>
              ) : data.credit_score < 90 && data.credit_score >= 76 ? (
                data.providers >= 15 ? (
                  <Badge className=" Gradebox tempGradeB">
                    B - APPROVED, it is moderate risk.
                  </Badge>
                ) : data.providers >= 8 ? (
                  <Badge className="Gradebox tempGradeD">
                    D - Extreme Risk - NOT APPROVED.
                  </Badge>
                ) : (
                  <Badge className="Gradebox tempGradeE">
                    E - Too New to Rate - NOT APPROVED.
                  </Badge>
                )
              ) : data.credit_score < 76 && data.credit_score >= 60 ? (
                <Badge className="Gradebox tempGradeC">
                  C - High Risk - Check with Office for Approval.
                </Badge>
              ) : data.credit_score < 60 && data.credit_score >= 1 ? (
                data.providers >= 8 ? (
                  <Badge className="Gradebox tempGradeD">
                    D - Extreme Risk - NOT APPROVED.
                  </Badge>
                ) : (
                  <Badge className="Gradebox tempGradeE">
                    E - Too New to Rate - NOT APPROVED.
                  </Badge>
                )
              ) : (
                <Badge className="Gradebox tempGradeF">
                  F - Not Available Scores - NOT APPROVED.
                </Badge>
              )}
            </View>
            {(data.credit_score >= 90 || (data.credit_score < 90 && data.credit_score >= 76 && data.providers >= 15)) && (
    <TableRow className="Gradeboxone" style={{width:'75%', maxWidth:'75%'}}>
      <TableCell colSpan={2}>
        <Alert variation="warning" height={"10%"}>
          Please contact your account manager at 18 Wheel Funding LLC to get a response on the approval request for the searched broker, due to recent financial difficulties of some brokers, credit check approvals are NOT FINAL and are subject to further review by our credit area.
        </Alert>
      </TableCell>
    </TableRow>
  )}
  </Grid>
         
        </View>
      </Flex>
    </div>
  );
};
